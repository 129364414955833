import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { QRCodeCanvas } from "qrcode.react";
import Alert from 'react-bootstrap/Alert'; // Import Bootstrap's Alert component
import { useNavigate } from 'react-router-dom';


function Forms() {
  const [show, setShow] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [showAlert, setShowAlert] = useState(false); // For showing transaction failure alert
  const navigate = useNavigate(); // Initialize useNavigate


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    simulatePaymentResponse();
  };
  const form = useRef();

  const getdata = useSelector((state) => state.cartreducer.carts);
  let globalTotal = 0;

  let arr = getdata.map((ele) => {
    return ele.rname + "#" + ele.qnty + "#" + ele.somedata + "#" + ele.price;
  });

  let finalarray = arr.map((element) => {
    return element.split("#").map((ele) => {
      return ele + ' ';
    });
  });

  let str = '';
  let total = 0;  

  for (let i = 0; i < finalarray.length; i++) {
    let arr1 = finalarray[i];
    str += 'Product Name :- ' + arr1[0] + '\n';
    str += 'Product Quantity :- ' + arr1[1] + '\n';
    str += 'Product Details :- ' + arr1[2] + '\n';
    str += 'Product Price :- ' + arr1[3] + '\n';
    str += '\n';
    
    total += arr1[1] * arr1[3];
  }

  globalTotal = total;
  str += '----------------------------------------------';
  str += '\nTotal:- ' + total;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_g9t85ou", "template_thvvzje", form.current, {
        publicKey: "xkGY50idhqDJuqLzG",
      })
      .then(
        () => {
            // alert('Email has been sent');
        },
        (error) => {
          // alert("FAILED...", error.text);
        }
      );
  };

  const [telephone, setTelephone] = useState('');
  const handleTelephoneChange = (event) => {
    const { value } = event.target;
    if (value.length <= 15) {
      setTelephone(value);
    }
  };

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const handleChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsValid(validateEmail(inputEmail));
  };

  const validateEmail = (email)=> {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Function to generate a random 10-digit number
  const generateRandomPhoneNumber = () => {
    return Math.floor(Math.random() * 9000000000) + 1000000000; // Generates a 10-digit number
  };

  // Generate random `pa` value
  const randomPhoneNumber = generateRandomPhoneNumber();

  // Dynamically modify the QR code string to include the total amount and random number
  const qrString = `upi://pay?pa=9899142405@hdfcbank&pn=hashbug&tn=${randomPhoneNumber}&am=${globalTotal}&url=`;

 // Simulate a payment response (success or failure)
const simulatePaymentResponse = () => {
  setTimeout(() => {
    const isSuccess = Math.random() > 0.5; // 50% chance of success
    if (isSuccess) {
      setPaymentStatus('Transaction Successful');
      navigate('/payment-success'); // Redirect to success page
    } else {
      setPaymentStatus('Transaction Failed');
      setShowAlert(true); // Show transaction failure alert
      navigate('/payment-success');
    }
    handleClose(); // Close the modal in both cases
  }, 180000); // 2 seconds delay for demonstration
};

  return (
    <div className="container my-5">
      <h3 className="text-center pb-2 mb-5" style={{borderBottom:'1px solid black'}}> Checkout Form</h3>

      {/* Show alert if payment fails */}
      {showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          <Alert.Heading>Transaction Failed</Alert.Heading>
          <p>
            The payment transaction failed. Please try again.
          </p>
        </Alert>
      )}

      <Form className="col-12 d-flex flex-wrap-reverse align-items-center justify-content-around" ref={form} onSubmit={sendEmail}>
        <div style={{width:"25em"}}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name <sup style={{color:'red'}}>*</sup> </Form.Label>
            <Form.Control type="text" placeholder="Enter name" name="user_name" required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email <sup style={{color:'red'}}>*</sup></Form.Label>
            <Form.Control type="email" onChange={handleChange} style={{ borderColor: isValid ? 'green' : 'red' }} placeholder="Enter email" name="user_email" required />
            <div style={{ color: isValid ? 'green' : 'red', display: isValid ? 'none' : 'block' }}>
              {isValid ? 'Email is valid.' : 'Email is invalid.'}
            </div>
          </Form.Group>
          <Form.Group controlId="formTelephone">
  <Form.Label>
    Telephone <sup style={{ color: 'red' }}>*</sup>
  </Form.Label>
  <Form.Control
    type="tel"
    name="user_number"
    value={telephone}
    onChange={handleTelephoneChange}
    maxLength={10}
    placeholder="Enter telephone number"
  />
</Form.Group>
<Form.Group className="mb-3" controlId="formBasicMessage">
  <Form.Label>
    Address <sup style={{ color: 'red' }}>*</sup>
  </Form.Label>
  <Form.Control type="text" name="user_address" placeholder="Enter address" required />
</Form.Group>
<Form.Group className="mb-3" controlId="formBasicMessage">
  <Form.Label>
    State <sup style={{ color: 'red' }}>*</sup>
  </Form.Label>
  <Form.Control type="text" name="user_address" placeholder="Enter State" required />
</Form.Group>

<Form.Group className="mb-3" controlId="formBasicMessage">
  <Form.Label>
    City <sup style={{ color: 'red' }}>*</sup>
  </Form.Label>
  <Form.Control type="text" name="user_address" placeholder="Enter City" required />
</Form.Group>


<Form.Group className="mb-3" controlId="formPincode">
  <Form.Label>
    Pincode <sup style={{ color: 'red' }}>*</sup>
  </Form.Label>
  <Form.Control
    type="text"
    name="user_pincode"
    pattern="\d{6}"
    maxLength={6}
    placeholder="Enter 6-digit pincode"
    required
  />
 
</Form.Group>

<Form.Group className="mb-3" controlId="formBasicMessage">
  <Form.Label>Addtional Details</Form.Label>
  <Form.Control type="text" name="message" placeholder="Enter message" />
</Form.Group>

          <button className="button" onClick={handleShow}>
            <span className="button_lg">
              <span className="button_sl"></span>
              <span className="button_text">Pay now</span>
            </span>
          </button>
          {/* {paymentStatus && <h5>{paymentStatus}</h5>} */}
        </div>
        <div style={{width:"25em"}}>
          <Form.Group controlId="formCart Values">
            <Form.Label>
              <h3 className="mb-2" style={{borderBottom:'1px solid black'}}>Cart Details</h3>
            </Form.Label>
            <Form.Control as="textarea" style={{height:"13rem",backgroundColor:"white",color:"black"}} name="cart" value={str} />
          </Form.Group>
        </div>
      </Form>
      
      <Modal show={show} onHide={handleClose} className="modal rounded-0">
        <Modal.Header closeButton>
          <Modal.Title>
            <center>Hashbug</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* QR code displayed here */}
          <center><h4>Pay now your payment through UPI</h4></center>
        <center className="mt-5">  <QRCodeCanvas value={qrString} size={156} /></center>
        </Modal.Body>
        <Modal.Footer>
        {paymentStatus && <h7>{paymentStatus}</h7>}
          <h4>Total Payment: {globalTotal}</h4>
          {/* <Button className="bg-success text-white">Pay Now!</Button> */}
          <br />  
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Forms;
